// TODO UPRAV
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Banner, BannerStyle, TeamCollection } from "@igloonet-web/shared-ui"
import { useActiveEmployees } from "../hooks/use-active-employees"
import ProfileImage from "../components/profile-image"

const Tym: React.FC = () => {
  const employees = useActiveEmployees()

  const divisions = [
    ["marketing", "Markeťáci"],
    ["vyvoj", "Vývoj"],
    ["hosting", "Hosting"],
    ["administrativa", "Back office"],
    ["obchod", "Obchod"],
  ]

  return (
    <Layout>
      <Helmet>
        <title>Tučňáci v igloo | igloonet</title>
        <meta
          name="description"
          content="Kdo se postará o vaše facebookové kampaně a s kým budete řešit hosting? Seznamte se s úžasným týmem igloonetu."
        />
      </Helmet>

      <Banner
        style={BannerStyle.Tym}
        orangeHeading="Kdo tahá"
        blackHeading="za náš provaz"
      />

      <TeamCollection
        imageTag={ProfileImage}
        teamMembers={employees.filter((e) => e.teams.includes("vedeni"))}
        title="Vedení"
      />

      {divisions.map((division, i) => (
        <TeamCollection
          key={i}
          imageTag={ProfileImage}
          teamMembers={employees.filter((e) => e.division === division[0])}
          title={division[1]}
        />
      ))}
    </Layout>
  )
}

export default Tym
